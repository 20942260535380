@import '~antd/dist/antd.css';

body {
  margin: 0;
  // font-family: Inter, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  // sans-serif;
  font-weight: 500;
  font-style: normal;
  line-height: 20px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --primary-dark: #101820;
  --primary-gold: #f7be00;
  --cool-gray: #6b7280;
  --cool-gray-50: #F9FAFB;
  --light-gray: #F8F8F8;
  --positive: #1d8759;
  --dark-blue: #2563eb;
  --negative: #e11d48;
  --cool-gray-400: #9ca3af;
  --disabled-btn: #F3F4F6;
}

h1 , h2 , h3 , h4 , h5 , h6 , p{
  margin-bottom: 0px;
}

.input-error{
  border-color: var(--negative) !important;
}

.input-label-error{
  color: var(--negative) !important;
}

.ant-btn{
  color: #FFFFFF;
  border: none;
  border-radius: 4px;
  &:disabled{
    background-color: var(--disabled-btn) !important;
    color: #9CA3AF !important;
  }

  &:hover , &:focus , &:active{
    color: #FFFFFF;
    border: none;
    box-shadow: none;
    outline: none;
  }

}

.ant-btn-link {
  cursor: pointer;
  color: var(--primary-dark);
}

.anticon-eye-invisible svg{
  width: 15px;
  height: 15px;
}

.bg-gray-50{
  background: #F9FAFB;
  border-radius: 4px;
}

.text-sm{
  font-size: 12px;
}

.text-grey-700{
  color: #374151;
}

.ml-10{
  margin-left: 10px;
}

.p-10{
  padding: 10px;
}

.flex{
  display: flex;
} 

.text-center{
  text-align: center;
}

.items-center{
  align-items: center;
}

.space-between{
  justify-content: space-between;
}

.justify-center{
  justify-content: center;
}

.justify-end{
  justify-content: end;
}

.transparent-ant-button{
  background: #FFFFFF;
  border: 1px solid #000000;
  box-sizing: border-box;
  /* shadow-base */
  box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);
  border-radius: 4px;
  color: #101820;
}

.ant-menu-light .ant-menu-item:hover{
  color: #f7be00;
}

.logOut-text{
  color: #f43f5e;
  width: 100%;
  border-radius: 4px;
}

.logOut-text:hover{
  font-weight: 700;
}

.ant-checkbox-inner{
  width: 20px;
}

.ant-checkbox + span{
  font-size: 12px;
  padding-top: 5px;
  padding-right: 0px;
}

.ant-input , .ant-input-affix-wrapper{
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}

.ant-input-affix-wrapper > input.ant-input{
  border: none !important;
  box-shadow: none !important;
}

.pagination-records{
  background: #d3d3d373;
  padding: 5px 10px;
  border-radius: 4px;
}


.btn-success{
  background-color: var(--positive) !important;
  color: white !important;
}

.btn-danger{
  background-color: var(--negative) !important;
  color: white !important;
}

.btn-info{
  background-color: var(--primary-gold) !important;
  color: black !important;
}