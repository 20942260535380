.authLayout {
    display: flex;
    min-height: 100vh;
    justify-content: center;
    align-items: center;
    padding:50px 0px;
  }
  
  .victorLogo {
    width: 160px;
    margin-bottom: 45px;
  }
  
  .authBox {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    max-width: 544px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    padding: 36px 62px 77px 58px;
    background: #FFFFFF;
  
    form {
      gap: 16px;
  
      div {
        // display: flex;
        // flex-direction: column;
        width: 100%;
      }

      .ant-input-affix-wrapper{
        outline: none;
        padding: 0px 11px;
        border-radius: 4px;
        border: 1px solid #E5E7EB;
        box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);

        &:hover{
          border: 1px solid #E5E7EB;
          box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);
        }

        .ant-input:focus , .ant-input{
          border:none;
          box-shadow: none;
          outline: none;
        }
      }

      .ant-input-affix-wrapper-focused{
        border-color: #101820;
        border: 1px solid #101820 !important;
      }

      .ant-input{
        height: 36px;
        outline: none;
        border-radius: 4px;
        border: 1px solid #E5E7EB;
        box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);

        &:focus{
          border: 1px solid #101820;
        }
      }

    }
  
    label {
      color: var(--cool-gray);
    }
  
    .error {
      height: 40px;
      display: flex;
      flex-direction: row;
      align-items: center;
      background: #ffe4e6b4;
      border-radius: 4px;
      color: #E11D48;
      padding: 0 10px;
      // margin: 14px 0;
  
      svg {
        margin-right: 15px;
      }
    }

    .success {
      height: 40px;
      display: flex;
      flex-direction: row;
      align-items: center;
      background: #93ebe4a8;
      border-radius: 4px;
      color: #0D9488;
      padding: 0 10px;

      svg {
        margin-right: 15px;
        margin-left: 5px;
      }
    }
  
    .authSubmit{
      height: 36px;
      margin-top: 8px;
      border-radius: 4px;
    }
  
  }
  
  .text-teal-600{
    color: #0D9488;
    font-weight: 600;
  }

  .otp-input{
    align-items: center;
    flex-direction: row !important;
    justify-content: center;

    Input{
      width: 40px;
      text-align: center;
    }
  }
  .ant-picker-input{flex-direction: row!important;}
  .ant-select-selection-item{overflow: visible!important;}


  .selectionBox{
    cursor: pointer;
    text-align: center;
    border-radius: 10px;
    box-shadow: 1px 2px 4px lightgrey;

    &:hover{
      -moz-transform: scale(1.06);
      -webkit-transform: scale(1.06);
      -o-transform: scale(1.06);
      -ms-transform: scale(1.06);
      -webkit-transform: scale(1.06);
      transform: scale(1.06);
      -webkit-transition: transform .6s ease-in-out;
      -moz-transition:transform .6s ease-in-out;
      -ms-transition:transform .6s ease-in-out;
    }

    .anticon{
      position: absolute;
      top: -10px;
      right: -10px;
      fontSize: 45px;
      font-size: 25px;
      color: #0083be;
    }
  }

  .activeClass{
    transform: scale(1.06);
    border: 1px solid #0083be;
  }
  
  .ant-form-item-label{
    padding: 6px !important;

    label{
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: left;
      position: relative;
      display: inline-flex;
      align-items: center;
      height: 32px;    
      color: rgba(16,24,32,.85);
    }
}

.ant-form-item-control {

  .ant-select-selector , .ant-input , .ant-picker{
    border: 1px solid #E5E7EB !important;
    box-shadow: 0px 1px 2px rgb(31 41 55 / 8%) !important;
    border-radius: 4px !important;
    max-height: 32px;
  }
}

.ant-input-number-handler-wrap{
  width: auto !important;
}

.ant-form {
  input[type=file]{
    display: none !important;
  }
}

.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  text-align: center;
}

.ant-checkbox-inner{
  height: 20px;
}

.ant-checkbox-wrapper{
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.policy {
  .ant-checkbox-wrapper{
    justify-content: flex-start;
  }
}

.ant-btn-dangerous{
  color: #ff4d4f !important;
  border: 1px solid #ff4d4f !important;
  background: #fff !important;
}

.jumioModal {
  .ant-modal-content{
    height: 100%;
    .ant-modal-body{
      height: 100%;
    }
  }
}

.shiftiProModal {
  .ant-modal-content{
    height: 100%;
    .ant-modal-body {
      padding: 0px;
      height: 100%;
    }
  }
}